import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { LangService } from "../../../../services/lang.service";
import { UserprefService } from "../../../../services/userpref.service";
import { jSith } from "../../../../util/jquery-replacement";
import { FreshbooksService } from "../../../../services/freshbooks.service";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";

@TypeManagerDecorator("s25-ng-invoice")
@Component({
    selector: "s25-ng-invoice",
    template: `<div *ngIf="init" class="S25Invoice">
        <div>
            <button
                *ngIf="bean.showCreateInvoiceButton"
                class="aw-button aw-button--outline c-margin-top--half c-margin-bottom--half c-margin-right--quarter"
                (click)="createOrOverwriteInvoice()"
            >
                {{ invoiceLang.invoice_event }}
            </button>

            <s25-ng-modal #exportInvoiceModal [title]="invoiceLang.invoice_event" [size]="'xs'">
                <ng-template #s25ModalBody>
                    <s25-ng-modal-invoice-freshbooks
                        [data]="invoiceData"
                        [invoiceLang]="invoiceLang"
                        (close)="exportInvoiceModal.close()"
                        (updateInvoiceData)="handleInvoiceUpdate()"
                    ></s25-ng-modal-invoice-freshbooks>
                </ng-template>
            </s25-ng-modal>

            <button
                *ngIf="bean.hasInvoices && !bean.showInvoices"
                class="aw-button aw-button--primary c-margin-bottom--half c-margin-left--quarter"
                (click)="getInvoiceInfo()"
            >
                Show Invoices
            </button>
            <div *ngIf="bean.showInvoices">
                <table *ngFor="let invoice of bean.setInvoices" class="tblInvoiceSummary" align="center">
                    <tr>
                        <td class="ngRightpadMed">
                            <span><b>Invoice Created: </b></span>
                            <span class="nowrap">{{ invoice?.date | dateFormat }}</span>
                            <span class="ngRightpad">
                                <a href="{{ invoice?.links.view }}">View</a>
                            </span>
                            <span>
                                <a href="{{ invoice?.links.edit }}">Edit</a>
                            </span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Invoice Number:</b><span> {{ invoice?.number }}</span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Status:</b><span> {{ invoice?.status }}</span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Paid:</b><span> {{ invoice?.paid | currency }}</span>
                        </td>
                        <td>
                            <b>Outstanding:</b><span> {{ invoice?.amount_outstanding | currency }}</span>
                        </td>
                    </tr>
                </table>

                <div *ngIf="showRelatedReminder">{{ showRelatedReminder }}</div>

                <table *ngFor="let invoice of bean.eventInvoices" class="tblInvoiceSummary" align="center">
                    <tr>
                        <td class="ngRightpadMed">
                            <span><b>Invoice Created:</b></span>
                            <span class="nowrap">{{ invoice?.date | dateFormat }}</span>
                            <span class="ngRightpad">
                                <a href="{{ invoice?.links.view }}">View</a>
                            </span>
                            <span>
                                <a href="{{ invoice?.links.edit }}">Edit</a>
                            </span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Invoice Number:</b><span> {{ invoice?.number }}</span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Status:</b><span> {{ invoice?.status }}</span>
                        </td>
                        <td class="ngRightpadMed">
                            <b>Paid:</b><span> {{ invoice?.paid | currency }}</span>
                        </td>
                        <td>
                            <b>Outstanding:</b><span> {{ invoice?.amount_outstanding | currency }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        .S25Invoice .aw-button.aw-button--outline {
            background-color: #fff !important;
        }

        .S25Invoice .aw-button.aw-button--outline:focus,
        .S25Invoice .aw-button.aw-button--outline:hover {
            color: #fff !important;
            background-color: #3273a0 !important;
        }
    `,
})
export class S25InvoiceComponent implements OnInit {
    @Input() s25Invoices: any;
    @Input() orgId: number;
    @Input() eventId: number;
    @Input() billId: number;
    @Input() isSetMode: boolean;
    @Input() hasRelatedEvents: boolean;

    @Output() refreshInvoices: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("exportInvoiceModal") exportInvoiceModal: S25ModalComponent;

    init: boolean;
    invoiceLang: any;
    isSetModeBool: boolean;
    hasRelatedEventsBool: boolean;
    bean: any;
    showRelatedReminder: string;
    dateFormat: any;
    invoiceData: any;
    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.isSetModeBool = !this.billId && S25Util.toBool(this.isSetMode);
        this.hasRelatedEventsBool = !this.billId && S25Util.toBool(this.hasRelatedEvents);

        this.bean = {
            showCreateInvoiceButton: true,
            showInvoiceSummary: true,
            showInvoices: false,
            hasInvoices: false,
        };

        if (this.hasRelatedEventsBool && !this.isSetModeBool) {
            this.showRelatedReminder =
                "This event has related events which can be invoiced together. Check 'Combine Related Events' above to see the pricing details for all of them.";
        }

        const [lang, dateFormat] = await Promise.all([LangService.getLang(), UserprefService.getS25Dateformat()]);

        this.invoiceLang = lang?.div.controls["s25-invoice"].text;
        this.invoiceLang.invoice_event = "Export Invoice"; //STORY-3142, STORY-2513
        this.dateFormat = dateFormat;

        const invoiceData = this.s25Invoices?.invoice;
        const byOrgId = (inv: any) => inv.organization_id === this.orgId;

        this.bean.hasInvoices =
            invoiceData?.setInvoices?.filter(byOrgId).length ||
            invoiceData?.eventInvoices?.filter(byOrgId).length ||
            invoiceData?.customBillInvoices?.filter(byOrgId).length;

        this.init = true;
        this.cd.detectChanges();
    }

    async getInvoiceInfo() {
        this.bean.showInvoices = this.s25Invoices?.invoice;
        const byOrgId = (inv: any) => inv.organization_id === this.orgId;
        let setPromises: any = [];
        let eventPromises: any = [];

        //if in set mode, fetch invoice in this set and org; note: only 1 invoice should be in here since this is an exact set and org...
        this.isSetModeBool &&
            jSith.forEach(this.s25Invoices?.invoice?.setInvoices?.filter(byOrgId), (_, inv) => {
                setPromises.push(FreshbooksService.getInvoice(inv.invoice_id, inv.instance_id));
            });

        //if not in set mode, fetch invoice in this event and org; note: only 1 invoice should be in here since this is an exact event and org...
        !this.isSetModeBool &&
            jSith.forEach(this.s25Invoices?.invoice?.eventInvoices?.filter(byOrgId), (_, inv) => {
                eventPromises.push(FreshbooksService.getInvoice(inv.invoice_id, inv.instance_id));
            });

        this.billId &&
            jSith.forEach(this.s25Invoices?.invoice?.customBillInvoices?.filter(byOrgId), (_, inv) => {
                eventPromises.push(FreshbooksService.getInvoice(inv.invoice_id, inv.instance_id));
            });

        this.bean.setInvoices = await Promise.all(setPromises);
        this.bean.eventInvoices = await Promise.all(eventPromises);

        this.cd.detectChanges();
    }

    createOrOverwriteInvoice() {
        this.invoiceData = {
            billId: this.billId,
            eventId: this.eventId,
            orgId: this.orgId,
            isSetMode: this.isSetModeBool,
        };

        this.exportInvoiceModal.open();
    }

    handleInvoiceUpdate() {
        this.refreshInvoices.emit();
    }
}
