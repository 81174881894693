import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal/modal.header.component";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-tax-schedules
                    [tax]="{ itemId: data.id }"
                    [mode]="data.mode"
                    (saved)="onSaved()"
                    (cancelled)="onCancelled()"
                ></s25-ng-tax-schedules>
            </div>
        </div>
    `,
    styles: `
        ::ng-deep s25-ng-tax-schedules .pricingTaxSchedules > :not(div) {
            display: none;
        }

        ::ng-deep s25-ng-tax-schedules .pricingTaxSchedules > div {
            margin-top: 0 !important;
        }

        ::ng-deep s25-ng-tax-schedules .buttons {
            margin-top: 8em !important;
            padding-bottom: 0 !important;
        }

        ::ng-deep s25-ng-tax-schedules .buttons button:last-child {
            display: none;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditTaxScheduleComponent implements OnInit {
    @Input() data: {
        id: number;
        mode: "create" | "copy" | "edit";
        onSave?: Function;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
